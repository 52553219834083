<template>
  <div v-if="currentDose">

  <!-- Eligble Card -->
    <div justify="center" v-if="(this.user.nextDose.isEligible && !this.user.nextDose.scheduled)">
      <v-card style="padding:0px; margin: 0px;">
        <v-card-title primary-title>
            <h3 v-html="currentDose.eligibilityMessage"></h3>
        </v-card-title>
        <v-card-text style="padding:0px; margin: 0px;">
          <v-container fluid>
            <div id="step1" v-if="currentStep===1">
              <v-row>
                <v-col>
                  <h2>Step 1:</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>Enter your zip code so we can find locations close to you:</p>
                </v-col>
              </v-row>
              <form v-on:submit.prevent="submitZip">
              <v-row>
                <v-col cols="12" md="4" sm="12">
                  <v-text-field
                    v-model="zipCode"
                    v-mask="'#####'"
                    :rules="zipRules"
                    :error-messages="zipcodeError"
                    label="Zip Code"
                    type="tel"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                  <v-btn style="width: 100%;" x-large @click="submitZip">Find Locations</v-btn>
                </v-col>
              </v-row>
              </form>
            </div>
            <location-search
              id="step2"
              v-if="currentStep===2"
              :user="user"
              :zipCode="zipCode"
              :key="locationSearchKey"
              :currentDoseType="currentDoseType"
              :vaccineManufacturerId="vaccineManufacturerId"
              @locationSelected="locationSelected"
              @goBack="zipCode = null; goToStep(1)"
            />
            <appointment-search
              id="step3"
              v-if="currentStep === 3"
              :user="user"
              :selectedLocation="selectedLocation"
              :eligibleDate="user.secondDose && user.secondDose.eligibleStartDateUtc ? user.secondDose.eligibleStartDateUtc : ''"
              @goBack="goToStep(2)"
              @appointmentSelected="appointmentSelected"
            />
            <confirm-appointment
              id="step4"
              v-if="currentStep === 4"
              :selectedLocation="selectedLocation"
              :selectedAppointment="selectedAppointment"
              :currentDoseType="currentDoseType"
              :user="user"
              @goBack="goToStep(3)"
              @bookedAppointment="bookedAppointment"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </div>

    <!-- Not Eligible Card -->
    <div justify="center" v-if="!this.user.nextDose.isEligible">
      <v-card>
        <v-card-title>
          <h3 v-html="currentDose.eligibilityMessage"></h3>
        </v-card-title>
        <v-card-text>
          <h3>{{ currentDose.eligibilityDetails }}</h3>
        </v-card-text>
      </v-card>
    </div>

    <!-- Already Scheduled card -->
    <already-scheduled
      v-if="(this.user.nextDose.isEligible && this.user.nextDose.scheduled)"
      :user="user"
      :scheduledAppointment="currentDose.scheduled"
      :currentDose="currentDose"
      :currentDoseType="currentDoseType"
      @releasedAppointment="releasedAppointment"
    />
      
    <div v-if="currentRouteName === 'proxysignup'" >
      <v-card>
        <v-card-actions>
          <v-btn @click="closeProxyDialog" x-large style="width: 100%;" color='info'>Close</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  props: {
    user: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  components: {
    manufacturerChip: () => import('@/module/vaccineManufacturer/components/manufacturerChip'),
    locationSearch: () => import('@/module/vaccineScheduling/signup/components/locationSearch'),
    appointmentSearch: () => import('@/module/vaccineScheduling/signup/components/appointmentSearch'),
    confirmAppointment: () => import('@/module/vaccineScheduling/signup/components/confirmAppointment'),
    alreadyScheduled: () => import('@/module/vaccineScheduling/signup/components/alreadyScheduled'),
    dosesCompleted: () => import('@/module/vaccineScheduling/signup/components/dosesCompleted')
  },
  watch: {
    zipCode: function () {
      this.zipcodeError = []
    }
  },
  data() {
    return {
      zipCode: null,
      currentStep: 1,
      locationSearchKey: 1,
      selectedAppointment: { },
      selectedLocation: { },
      zipRules: [
        v => !!v || 'A zip code is Required',
        v => /^\d{5}$/.test(v) || 'A valid zip code must be provided'
      ],
      zipcodeError: [ ]
    }
  },
  computed: {
    currentDose: {
      get: function () {
        if(this.user) {
            return this.user.nextDose
        }
        return null
      }
    },
    currentRouteName: function () {
        return this.$route.name;
    },
    currentDoseType: {
      get: function () {
        if(this.user) {
          return 'Next'
        }
        return null
      }
    },
    vaccineManufacturerId: {
      get: function () {
        if(this.user && this.user.nextDose.administered) {
          return this.user.nextDose.administered.vaccineManufacturerId
        }
        return 0
      }
    }
  },
  methods: {
    scrollToTop: function () {
      window.scrollTo(0, 0)
    },
    submitZip: function () {
      if (!this.zipCode) {
        this.zipcodeError = [ 'A valid zip code must be provided' ]
        return false
      }
      if (this.zipCode.length !== 5) {
        return false
      }
      this.currentStep = 2
      this.scrollToTop()
    },
    reset: function () {
      this.zipCode = null
      this.currentStep = 1
      this.selectedLocation = null
      this.selectedDay = null
      this.selectedAppointment = null
      this.locations = [ ]
    },
    locationSelected: function (selectedLocation) {
      this.selectedLocation = selectedLocation
      this.goToStep(3)
      this.scrollToTop()
    },
    appointmentSelected: function (appointment) {
      this.selectedAppointment = appointment
      this.goToStep(4)
      this.scrollToTop()
    },
    goToStep: function (step) {
      this.currentStep = step
    },
    bookedAppointment: function () {
      this.$emit('refreshAppointment')
    },
    releasedAppointment: function () {
      this.$emit('refreshAppointment')
    },
    closeProxyDialog: function() {
      this.$emit('closeProxyDialog')
    },
    getPrettyTime: function (date) {
      return this.convertToLocal(date).format('MMM Do')
    },
    convertToLocal: function (date) {
      return moment.utc(date).local()
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
  .vaccine-alert-window {
    margin-top: 20px;
  }
</style>